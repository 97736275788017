@import '../../styles/variables';
@import '../../styles/grid';

.component--navigation {
  @include container;

  .component--navigation__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    .component--navigation__items--desktop {
      display: block;

      .component--navigation__item {
        margin-left: 24px;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: transparent;
          bottom: -12px;
          left: calc(50% - 2px);
          transition: all 0.2s ease-out;
        }

        &.active {
          &::before {
            background-color: $color__gray--light;
          }
        }
      }
    }

    .component--navigation__items--mobile {
      display: none;
      
      .component--navigation__items--mobile__menu-container {
        cursor: pointer;

        .component--navigation__items--mobile__menu-bar {
          width: 20px;
          height: 2px;
          background-color: $color__gray--light;
          margin: 4px 0;
          transition: all 0.2s ease-out;
        }
      }

      .component--navigation__item {
        margin-left: 24px;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: transparent;
          top: 32px;
          left: calc(50% - 48px);
          transition: all 0.2s ease-out;
        }

        &.active {
          &::before {
            background-color: $color__gray--light;
          }
        }
      }

      &.open {
        .component--navigation__items--mobile__menu-container {
          .component--navigation__items--mobile__menu-bar:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }
          
          .component--navigation__items--mobile__menu-bar:nth-child(2) {
            opacity: 0;
          }
  
          .component--navigation__items--mobile__menu-bar:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }

      .component--navigation__items--mobile__menu-list {
        position: absolute;
        top: 88px;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $color__white--tinted;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: opacity 0.2s ease-out 0.2s;
        opacity: 0;
        z-index: 1;
        pointer-events: none;

        &.open {
          opacity: 0.96;
          pointer-events: all;
        }

        .component--navigation__item {
          width: 100%;
          text-align: center;
          // margin: 24px 0;
          padding: 24px 0;
          border-top: 1px solid $color__gray--extra-light;
        }
      }
    }

    @media screen and (max-width: $viewport-small) {
      .component--navigation__items--desktop {
        display: none;
      }

      .component--navigation__items--mobile {
        display: block;
      }
    }
  }
}