@import '../../styles/variables.scss';

.component--section-divider {
  width: 100%;
  height: 2px;
  margin: 48px 0;
  display: flex;
  justify-content: center;

  .component--section-divider__line {
    width: 32px;
    height: 100%;
    text-align: center;
    background-color: $color__gray--extra-light;
  }

  @media screen and (max-width: $viewport-small) {
    margin: 24px 0;
  }
}