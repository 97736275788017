@import './styles';

.app--page-transition {
  &.fade-in {
    animation: 0.3s fadeIn forwards;
  }

  &.fade-out {
    animation: 0.5s fadeOut forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
    filter: blur(30px) grayscale(1);
    transform: translate(0, -15px);
  }
  100% {
    opacity: 1;
    filter: blur(0) grayscale(0);
    transform: translate(0, 0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: blur(0) grayscale(0);
    transform: translate(0, 0);
  }
  100% {
    opacity: 0.1;
    filter: blur(30px) grayscale(1);
    transform: translate(0, 30px);
  }
}

.visibility--desktop {
  display: block;
}

.visibility--mobile {
  display: none;
}

@media screen and (max-width: $viewport-small) {
  .visibility--desktop {
    display: none;
  }
  
  .visibility--mobile {
    display: block;
  }
}