@import '../../styles/grid';

.page--about {
  @include container;
  min-height: calc(100vh - 176px);
  padding-top: 32px;

  .page--about__portrait-container {
    padding-top: 32px;
    background-color: $color--gray__light;
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../..//assets/images/portrait.jpeg');
  }
}