@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--image {
  width: 100%;
  aspect-ratio: 16/9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid $color__white--tinted;
  border-bottom: none;
}