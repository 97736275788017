@import '../../styles/variables.scss';

.component--interactive-portrait {
  width: 100%;
  height: calc(100vh - 192px);
  // padding: 32px;

  .component--interactive-portrait__img {
    // width: calc(100% - 64px);
    width: 100%;
  }

  @media screen and (max-width: $viewport-small) {
    height: 50vh;
  }
}