@import '../../styles/variables';

.component--loader {
  position: fixed;
  z-index: 1000;
  left: 45vw;
  top: 30vh;
  pointer-events: none;
  filter: blur(30px);
  
  transition: all 0.5s ease-in-out;
  opacity: 0;
  &.active {
    filter: blur(6px);
    opacity: 0.7;
  }

  .loader-0 {
    position: absolute;
    border: 4px solid $color--gray__medium;
    opacity: 0.2;
    border-radius: 20%;
    width: 30vw;
    height: 30vw;
    top: 4vh;
    animation: spin 7.2s linear infinite;
  }

  .loader-1 {
    position: absolute;
    border: 4px solid $color--gray__medium;
    opacity: 0.35;
    border-radius: 10%;
    width: 20vw;
    height: 20vw;
    left: -10vw;
    animation: spin_opposite 4.8s linear infinite;
  }

  .loader-2 {
    position: absolute;
    border: 4px solid $color--gray__medium;
    opacity: 0.8;
    border-radius: 30%;
    width: 10vw;
    height: 10vw;
    top: -10vh;
    animation: spin 2.4s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin_opposite {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}