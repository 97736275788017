@import '../../styles/grid';

.page--work {
  @include container;
  padding-top: 32px;
  min-height: calc(100vh - 176px);

  .page--work__project {
    cursor: pointer;
    margin-bottom: 32px;

    .page--work__project__thumbnail {
      width: 100%;
      padding-bottom: 56.25%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: all 0.2s ease-out;
      margin-bottom: 8px;
    }

    .page--work__project__title {
      position: relative;
      width: fit-content;
      margin-bottom: 8px;

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color--gray__extra-light;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color--gray__dark;
        transform: scale(0, 1);
        transform-origin: left center;
        transition: transform 0.2s cubic-bezier(0.8, 0, 0.2, 1);
      }
    }

    &:hover {
      .page--work__project__thumbnail {
        filter: blur(2px) grayscale(100%);
      }

      .page--work__project__title {
        &:before {
          transform: scale(1, 1);
        }
      }
    }
  }
}