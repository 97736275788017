@import '../../styles/grid';

.page--detail {
  @include container;
  padding-top: 32px;
  min-height: calc(100vh - 176px);

  .page--detail__hero--video__container {
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 40vh;
    background-color: $color--gray__dark;
    display: flex;
    justify-content: center;

    .page--detail__hero--video__iframe {
      // width: 100%;
      // height: 100%;
      border: 0;
      aspect-ratio: 16/9;
    }
  }

  .page--detail__hero--image__container {
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 40vh;
    background-color: $color--gray__dark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .page--detail__project-title {
    margin-top: 32px;
  }

  .page--detail__back-to-home {
    padding: 48px 0 16px;
  }

  @media screen and (max-width: $viewport-small) {
    .page--detail__hero-image {
      padding-bottom: 1px;
    }
  }
}