@import './variables.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  white-space: pre-wrap;
  font-family: 'Lato', sans-serif;
  color: $color__gray--dark;

  &.inverse {
    color: $color__white;
  }

  &.all-caps {
    text-transform: uppercase;
  }
}

h1 {
  font-weight: 900;
  font-size: 52px;
  line-height: 64px;
  letter-spacing: 1px;
}

h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
}

h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0px;
}

h4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
}

h5 {
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
}

h6 {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
}

p {
  font-weight: 400;
  color: $color__gray--medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;

  &.small {
    font-size: 12px;
    line-height: 16px;
  }

  &.bold {
    font-weight: 700;
  }

  * {
    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  
    &.bold {
      font-weight: 700;
    }
  }
}

// CTAs
a {
  margin: 0;
  white-space: pre-wrap;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: $color__gray--dark;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;

  &.styled {
    position: relative;
    width: fit-content;
    margin-bottom: 8px;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color--gray__extra-light;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color--gray__dark;
      transform: scale(0, 1);
      transform-origin: left center;
      transition: transform 0.2s cubic-bezier(0.8, 0, 0.2, 1);
    }

    &:hover {
      &:before {
        transform: scale(1, 1);
      }
    }
  }

  &.external {
    color: $color__gray--dark;
    text-decoration: underline;
  }
}

// Query
@media only screen and (max-width: $viewport-small) {
  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
}