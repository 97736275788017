@import '../../styles/variables';

.component--data-not-found {
  height: calc(100vh - 176px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    cursor: pointer;
  }
}